import React from "react";
import { SimpleRollPage, ArtistsRoll } from "../../components/";

const ArtistsIndexPage: React.FC = () => (
  <SimpleRollPage title="Creators">
    <ArtistsRoll />
  </SimpleRollPage>
);

export default ArtistsIndexPage;
